.catalog-card {
  --section-bg-card: #f4f3f1;
  position: relative;
  width: 15.8125rem;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  gap: 0.625rem;
  overflow: hidden;
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .catalog-card {
      width: 9.88281vw;
      gap: 0.39062vw; } }
  @media screen and (max-width: 109.375rem) {
    .catalog-card {
      width: 11.875rem; } }
  @media screen and (max-width: 87.5rem) {
    .catalog-card {
      width: 10.4375rem; } }
  @media screen and (max-width: 39.375rem) {
    .catalog-card {
      width: 8.625rem;
      gap: 0.3125rem; } }
  @media (any-hover: hover) {
    .catalog-card:hover .catalog-card__image picture {
      -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
              transform: scale(1.1); } }
  .catalog-card__image {
    width: 100%;
    height: 11.25rem;
    border-radius: var(--radius-common);
    overflow: hidden;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media screen and (min-width: 131.25rem) {
      .catalog-card__image {
        height: auto;
        aspect-ratio: 360/256; }
        @supports not (aspect-ratio: 1/1) {
          .catalog-card__image {
            height: 16rem; } } }
    @media screen and (min-width: 160.0625rem) {
      .catalog-card__image {
        height: 7.42188vw; } }
    @media screen and (max-width: 109.375rem) {
      .catalog-card__image {
        height: 8.5rem; } }
    @media screen and (max-width: 87.5rem) {
      .catalog-card__image {
        height: 7.5rem; } }
    @media screen and (max-width: 39.375rem) {
      .catalog-card__image {
        height: 6rem; } }
    .catalog-card__image img,
    .catalog-card__image picture {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -o-object-fit: contain;
         object-fit: contain;
      -o-object-position: center;
         object-position: center;
      -webkit-transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      -o-transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier), -webkit-transform var(--animation-timing-medium) var(--cubic-bezier); }
  .catalog-card__name {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: var(--section-text-color-primary);
    text-align: center; }
    .catalog-card__name::before {
      content: "";
      position: absolute;
      top: -0rem;
      left: -0rem;
      width: calc(100% + 0rem * 2);
      height: calc(100% + 0rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .catalog-card__name::before {
          top: 0vw;
          left: 0vw;
          width: calc(100% + 0vw * 2);
          height: calc(100% + 0vw * 2); } }
  .catalog-card_border.catalog-card {
    padding: 1.875rem 1.25rem;
    border: 1px solid var(--stroke-dark);
    border-radius: var(--radius-common);
    -webkit-transition: -webkit-box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: -webkit-box-shadow var(--animation-timing) var(--cubic-bezier);
    -o-transition: box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing) var(--cubic-bezier), -webkit-box-shadow var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .catalog-card_border.catalog-card {
        padding: 1.17188vw 0.78125vw; } }
    @media screen and (max-width: 109.375rem) {
      .catalog-card_border.catalog-card {
        padding: 0.9375rem; } }
    @media (any-hover: hover) {
      .catalog-card_border.catalog-card:hover {
        -webkit-box-shadow: var(--shadow-large);
                box-shadow: var(--shadow-large); }
        .catalog-card_border.catalog-card:hover .catalog-card__image img {
          -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
                  transform: scale(1.1); } }
  .catalog-card_min {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: var(--section-bg-card);
    padding: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .catalog-card_min {
        padding: 0.19531vw; } }
    .catalog-card_min .catalog-card__image {
      height: 3.75rem;
      width: 3.75rem;
      min-width: 3.75rem; }
      @media screen and (min-width: 160.0625rem) {
        .catalog-card_min .catalog-card__image {
          height: 2.34375vw;
          width: 2.34375vw;
          min-width: 2.34375vw; } }
    .catalog-card_min .catalog-card__name {
      text-align: left; }
