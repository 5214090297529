@mixin mediaBigDesktop {
  @media screen and (min-width: #{rem(2561)}) {
    @content;
  }
}

@mixin mediaDesktop {
  @media screen and (min-width: #{rem(2100)}) {
    @content;
  }
}

@mixin mediaLaptop {
  @media screen and (max-width: #{rem(1750)}) {
    @content;
  }
}

@mixin mediaTablet {
  @media screen and (max-width: #{rem(1400)}) {
    @content;
  }
}

@mixin mediaMobile {
  @media screen and (max-width: #{rem(630)}) {
    @content;
  }
}

@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

//@mixin container($name, $width) {
//  container-type: inline-size;
//  container-name: $name;
//  @container (max-width: $width) {
//    @content;
//  }
//}
