@import '../../mixins';

.catalog-card {
  --section-bg-card: #f4f3f1;
  position: relative;
  
  width: rem(253);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: rem(10);

  overflow: hidden;

  cursor: pointer;

  @include mediaBigDesktop {
    width: big(253);
    gap: big(10);
  }

  @include mediaLaptop {
    width: rem(190);
  }

  @include mediaTablet {
    width: rem(167);
  }

  @include mediaMobile {
    width: rem(138);
    gap: rem(5);
  }

  @include hover {
    .catalog-card {
      &__image {
        & picture {
          transform: scale(1.1);
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: rem(180);

    //background-color: var(--section-bg-card);
    border-radius: var(--radius-common);
    overflow: hidden;

    pointer-events: none;
    user-select: none;
    
    @include mediaDesktop {
      height: auto;
      aspect-ratio: 360/256;
      
      @supports not (aspect-ratio:1/1) {
        height: rem(256);
      }
    }

    @include mediaBigDesktop {
      height: big(190);
    }

    @include mediaLaptop {
      height: rem(136);
    }

    @include mediaTablet {
      height: rem(120);
    }

    @include mediaMobile {
      height: rem(96);
    }

    & img,
    & picture {
      width: 100%;
      height: 100%;
      display: flex;

      object-fit: contain;
      object-position: center;

      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
    }
  }

  &__name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--section-text-color-primary);
    text-align: center;

    &::before {
      @include pseudo(0)
    }
  }
  
  &_border {
    &.catalog-card {
      padding: rem(30) rem(20);
    
      border: 1px solid var(--stroke-dark);
      border-radius: var(--radius-common);
      
      transition: box-shadow var(--animation-timing) var(--cubic-bezier);
      
      @include mediaBigDesktop {
        padding: big(30) big(20);
      }
      
      @include mediaLaptop {
        padding: rem(15);
      }
      
      @include hover {
        box-shadow: var(--shadow-large);

        & .catalog-card {
          &__image {
            & img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  &_min {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--section-bg-card);
    padding: rem(5);

    @include mediaBigDesktop {
      padding: big(5);
    }

    .catalog-card {
      &__image {
        height: rem(60);
        width: rem(60);
        min-width: rem(60);

        @include mediaBigDesktop {
          height: big(60);
          width: big(60);
          min-width: big(60);
        }
      }

      &__name {
        text-align: left;
      }
    }
  }
}
